<template>
  <div class="app-layout">
    <Navbar @toggleSidebar="toggleSidebar" />
    <div class="app-layout__content">
      <div class="app-layout__sidebar-wrapper" style="color: white" :class="{ minimized: isSidebarMinimized }">
        <div v-if="isFullScreenSidebar" class="flex justify-end">
          <VaButton class="px-4 py-4" icon="md_close" preset="plain" color="dark" @click="$emit('toggle-sidebar')" />
        </div>
        <Sidebar
          :width="sidebarWidth"
          :minimized="isSidebarMinimized"
          :minimized-width="sidebarMinimizedWidth"
          :animated="!isMobile"
        />
      </div>
      <div class="app-layout__page">
        <div class="p-2 md:px-6 md:py-9">
          <RouterView />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onBeforeUnmount, onMounted } from 'vue'
import { useStore } from 'vuex'
import Navbar from '../components/navbar/AppNavbar.vue'
import Sidebar from '../components/sidebar/AppSidebar.vue'

const store = useStore()

const isMobile = ref(false)
const isTablet = ref(false)
const sidebarMinimizedWidth = ref('0')
const sidebarWidth = ref('16rem')
const isSidebarMinimized = computed(() => store.state.isSidebarMinimized)
const checkIsTablet = () => window.innerWidth <= 768
const checkIsMobile = () => window.innerWidth <= 640
const setViewportScale = (scale) => {
  let metaViewport = document.querySelector('meta[name="viewport"]');
  if (!metaViewport) {

    metaViewport = document.createElement('meta');
    metaViewport.name = 'viewport';
    document.head.appendChild(metaViewport);
  }

  metaViewport.content = `width=device-width, initial-scale=${scale}, maximum-scale=${scale}, user-scalable=no`;
};

const onResize = () => {
  isSidebarMinimized.value = checkIsTablet();
  isMobile.value = checkIsMobile();
  isTablet.value = checkIsTablet();
  sidebarMinimizedWidth.value = isMobile.value ? '1' : '4.5rem';
  sidebarWidth.value = isTablet.value ? '100%' : '16rem';

  const zoomScale = isMobile.value ? 0.5 : 1;
  setViewportScale(zoomScale);
};

onMounted(() => {
  window.addEventListener('resize', onResize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize)
})

onResize()

const isFullScreenSidebar = computed(() => isTablet.value && !isSidebarMinimized.value)

const toggleSidebar = () => {
  store.commit('toggleSidebar')
}
</script>

<style lang="scss">
$mobileBreakPointPX: 640px;
$tabletBreakPointPX: 768px;

.app-layout {
  height: 100vh;
  display: flex;
  flex-direction: column;

  &__navbar {
    min-height: 2rem;
  }

  &__content {
    display: flex;
    height: calc(100vh - 4rem);
    flex: 1;

    @media screen and (max-width: $tabletBreakPointPX) {
      height: calc(100vh - 6.5rem);
    }

    .app-layout__sidebar-wrapper {
      position: relative;
      height: 100%;
      background: #ffffff00;

      @media screen and (max-width: $tabletBreakPointPX) {
        &:not(.minimized) {
          width: 100%;
          height: 100%;
          position: fixed;
          top: 0;
          z-index: 999;
        }

        .va-sidebar:not(.va-sidebar--minimized) {
          .va-sidebar__menu {
            padding: 0;
          }
        }
      }
    }
  }

  &__page {
    flex-grow: 2;
    overflow-y: scroll;
    background-color: #ffffff !important;
  }
}
</style>
